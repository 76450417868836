// @todo: Add comments and use debounce on screensize change
import debounce from 'lodash-es/debounce';

const initializeAccordions = (accordionHeaders) => {
    for (const accordionHeader of accordionHeaders) {
        const target = accordionHeader.parentElement.nextElementSibling;

        target.hidden = true;

        accordionHeader.addEventListener('click', () => {
            let expanded =
                accordionHeader.getAttribute('aria-expanded') === 'true' ||
                false;
            accordionHeader.setAttribute('aria-expanded', !expanded);
            target.hidden = expanded;
        });
    }
};

const initializeNewsletter = () => {
    const newsletterForms = document.querySelectorAll('.footer__signup');

    for (const newsletterForm of newsletterForms) {
        newsletterForm.addEventListener('submit', async (event) => {
            event.preventDefault();

            const form = event.currentTarget;
            const formData = new FormData(form);
            const action = form.getAttribute('action');
            const response = await fetch(action, {
                method: "POST",
                body: formData,
                headers: {
                    'X-Requested-With': 'XMLHttpRequest'
                },
            });

            const data = await response.json();
            const copy = form.querySelector('.footer__signup-copy');

            copy.innerHTML = data?.payload?.message ?? ''
            copy.classList.remove('footer__signup-copy--success');
            copy.classList.remove('footer__signup-copy--error');

            if (data.result) {
                copy.classList.add('footer__signup-copy--success');
                form.querySelector('.footer__signup-field').classList.add('footer__signup-field--hidden');
            } else {
                copy.classList.add('footer__signup-copy--error');
            }
        })
    }
}

document.addEventListener('DOMContentLoaded', () => {
    initializeNewsletter();

    const accordionHeaders = document.querySelectorAll('[data-footer-accordion-header]');

    const mediaQuery = window.matchMedia('(max-width: 999px)');

    // Check if the media query is true upon load
    if (mediaQuery.matches) {
        initializeAccordions(accordionHeaders);
    }

    mediaQuery.addEventListener('change', (e) => {
        if (e.matches) {
        initializeAccordions(accordionHeaders);
        }
    });
});
